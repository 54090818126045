import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/disclaimers.module.scss"

class Disclaimers extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {},language: {}});
  }
  componentDidMount(){
    if(this.props && this.props.data) {
      this.props.data.then(data => {
        this.setState({data: data});
        this.setState({language: this.props.language});
      });
    }
  }
  render() {
    return (
        <div id = {Styles.disclaimers}>
          {this.props.children}
          {(() => {
            switch (this.props.language) {
                case 'en-chrome':
                return <p>This free extension will update this browser's search settings to Yahoo. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank">Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank">Privacy Policy</a>, and that you may be presented with more optional offers.</p>;
                break;
                case 'en-firefox':
                return <p>This free extension will update this browser's search settings to Yahoo. By continuing, you acknowledge the <a href = "/privacy-policy/" target = "_blank">Privacy Policy</a>, <a href = "/terms-of-service/" target = "_blank">Terms of Service</a>, and that you may be presented with more optional offers.</p>;
                break;
                case 'en-edge':
                return <p>This free extension will update this browser's search settings to Yahoo. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank">Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank">Privacy Policy</a>, and that you may be presented with more optional offers.</p>;
                break;
                case 'en-safari':
                return <p>This free extension will update this browser's search settings to Yahoo. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank">Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank">Privacy Policy</a>, and that you may be presented with more optional offers.</p>;
                break;
                case 'en-clear-chrome':
                return <p>By clicking above, you agree to the <a href = "https://clearbar.app/terms-of-service/" target = "_blank">Terms of Service</a> and <a href = "https://clearbar.app/privacy-policy/" target = "_blank">Privacy Policy</a> for Clear, a Chromium based browser with web search results provided by Yahoo!</p>;
                break;
                case 'en-clear-edge':
                return <p>By clicking above, you agree to the <a href = "https://clearbar.app/terms-of-service/" target = "_blank">Terms of Service</a> and <a href = "https://clearbar.app/privacy-policy/" target = "_blank">Privacy Policy</a> for Clear, a Chromium based browser with web search results provided by Yahoo!</p>;
                break;
                case 'de-chrome':
                return <p>Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, bestätigen die Nutzungsbedingungen  <a href = "/privacy-policy/" target = "_blank">Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank">Datenschutzerklärung</a>, und dass Ihnen weitere optionale Angebote unterbreitet werden können.</p>;
                break;
                case 'de-firefox':
                return <p>Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, bestätigen die <a href = "/privacy-policy/" target = "_blank">Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank">Datenschutzerklärung</a> und kann mit zusätzlichen Angeboten vorgestellt werden.</p>;
                break;
                case 'de-edge':
                return <p>Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, bestätigen die Nutzungsbedingungen  <a href = "/privacy-policy/" target = "_blank">Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank">Datenschutzerklärung</a>, und dass Ihnen weitere optionale Angebote unterbreitet werden können.</p>;
                break;
                case 'de-safari':
                return <p>Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, bestätigen die Nutzungsbedingungen  <a href = "/privacy-policy/" target = "_blank">Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank">Datenschutzerklärung</a>, und dass Ihnen weitere optionale Angebote unterbreitet werden können.</p>;
                break;
                case 'fr-chrome':
                return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous reconnaissez la   <a href = "/privacy-policy/" target = "_blank">politique de confidentialité </a> et las <a href = "/terms-of-service/" target = "_blank">conditions de service</a>, et le fait que d'autres offres facultatives peuvent vous être présentées.</p>;
                break;
                case 'fr-firefox':
                return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous reconnaissez la   <a href = "/privacy-policy/" target = "_blank">politique de confidentialité </a> et las <a href = "/terms-of-service/" target = "_blank">conditions de service</a>, et le fait que d'autres offres facultatives peuvent vous être présentées.</p>;
                break;
                case 'fr-edge':
                return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous reconnaissez la   <a href = "/privacy-policy/" target = "_blank">politique de confidentialité </a> et las <a href = "/terms-of-service/" target = "_blank">conditions de service</a>, et le fait que d'autres offres facultatives peuvent vous être présentées.</p>;
                break;
                case 'fr-safari':
                return <p>Cette extension gratuite mettra à jour les paramètres de recherche de ce navigateur. En continuant, vous reconnaissez la   <a href = "/privacy-policy/" target = "_blank">politique de confidentialité </a> et las <a href = "/terms-of-service/" target = "_blank">conditions de service</a>, et le fait que d'autres offres facultatives peuvent vous être présentées.</p>;
                break;
                case 'it-chrome':
                return <p>Questa estensione gratuita aggiornerà le impostazioni di ricerca di questo browser. Continuando, riconosci i <a href = "/terms-of-service/" target = "_blank">Termini di servizio</a> e <a href = "/privacy-policy/" target = "_blank">l'Informativa sulla privacy</a> e ti potrebbero essere presentate ulteriori offerte facoltative.</p>;
                break;
                case 'it-firefox':
                return <p>Questa estensione gratuita aggiornerà le impostazioni di ricerca di questo browser. Continuando, accetti i <a href = "/terms-of-service/" target = "_blank">Termini di servizio</a>, <a href = "/privacy-policy/" target = "_blank">l'Informativa sulla privacy</a> e che ti potrebbero essere presentate ulteriori offerte facoltative.</p>;
                break;
                case 'it-edge':
                return <p>Questa estensione gratuita aggiornerà le impostazioni di ricerca di questo browser. Continuando, riconosci i <a href = "/terms-of-service/" target = "_blank">Termini di servizio</a> e <a href = "/privacy-policy/" target = "_blank">l'Informativa sulla privacy</a> e ti potrebbero essere presentate ulteriori offerte facoltative.</p>;
                break;
                case 'it-safari':
                return <p>Questa estensione gratuita aggiornerà le impostazioni di ricerca di questo browser. Continuando, riconosci i <a href = "/terms-of-service/" target = "_blank">Termini di servizio</a> e <a href = "/privacy-policy/" target = "_blank">l'Informativa sulla privacy</a> e ti potrebbero essere presentate ulteriori offerte facoltative.</p>;
                break;
              default:
                return <p>This free extension will update this browser's search settings to Yahoo. By continuing, you acknowledge the <a href = "/privacy-policy/" target = "_blank">Privacy Policy</a> and <a href = "/terms-of-service/" target = "_blank">Terms of Service</a>, and that you may be presented with more optional offers.</p>;
            }
          })()}
        </div>
    )
  }
}
export default Disclaimers
